import { Injectable } from '@angular/core';
import { PaginationData } from '@app/interfaces';
import { FileHelperService } from '@app/services';
import { ReportFilter } from '@reporting/interfaces/report-filter.interface';
import {
    ReportTableColumn,
    ReportTableData,
    ReportTableRow,
    ReportTableSheet,
} from '@reporting/interfaces/report-table-data-structure.interface';
import { Query } from '@reporting/services/queries/query';
import { AdditionalReportFieldsType } from '../../interfaces/additional-report-fields.interface';

interface FileConf {
    content: string;
    defaultName: string;
}

const fileConfs: Map<string, FileConf> = new Map<string, FileConf>([
    [
        'csv',
        {
            content: 'text/csv',
            defaultName: 'report-export.csv',
        },
    ],
    [
        'xlsx',
        {
            content: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            defaultName: 'report-export.xlsx',
        },
    ],
    [
        'pdf',
        {
            content: 'application/pdf',
            defaultName: 'report-export.pdf',
        },
    ],
]);

/*
    We are adding a generic within the class declaration in order to allow developers to pass in
    additional report fields with this class. In order to do so, the developer should add the interface
    to the AdditionalReportFieldsType file and pass an object of that type when instantiating a report.
    We allow the type, or void. By adding void to the generic, we allow for reports to be instatiated without
    additionalReportFields
*/
@Injectable()
export class Report<T = AdditionalReportFieldsType | void> {
    private _name: string;
    private _id: string = null;
    private _page = 1;
    private _sort: string = null;
    private _sortDirection: 'ASC' | 'DESC' | null = null;
    private _filters: ReportFilter[] = [];
    private _data: ReportTableData;
    private _meta: any;
    private _additionalParams?: [string, string][];

    additionalReportFields: T | null = null;

    constructor(private fileHelperService: FileHelperService) {}

    get data(): ReportTableData {
        return this._data;
    }

    get sheet(): ReportTableSheet | null {
        return this._data.sheets[0] ?? null;
    }

    get paginationData(): PaginationData | null {
        return this._meta.pagination ?? null;
    }

    name(name: string): Report<T> {
        this._name = name;
        return this;
    }

    id(id: string): Report<T> {
        this._id = id;
        return this;
    }

    filters(filters: ReportFilter[]): Report<T> {
        this._filters = filters;
        return this;
    }

    page(page: number): Report<T> {
        this._page = page;
        return this;
    }

    sort(sort: string | null, sortDirection: 'ASC' | 'DESC' | null = null): Report<T> {
        this._sort = sort;
        this._sortDirection = sortDirection;
        return this;
    }

    get(): Promise<Report<T>> {
        return this.fetch().then((data: ReportTableData) => {
            this._data = data;
            return new Promise<Report<T>>((resolve) => resolve(this));
        });
    }

    export(contentType: string): Promise<boolean> {
        const conf = fileConfs.get(contentType) ?? fileConfs.get('xlsx');

        const query = new Query(
            this._name,
            this._id,
            this._filters,
            null,
            this._sort,
            this._sortDirection,
            this._additionalParams
        );

        return query.fetchResponse(conf.content).then((response) => {
            return this.fileHelperService.saveFileFromResponse(response, conf.defaultName);
        });
    }

    withAdditionalReportFields(additionalReportFields: T): Report<T> {
        this.additionalReportFields = additionalReportFields;
        return this;
    }

    withAdditionalParameters(additionalParams?: [string, string][]): Report<T> {
        this._additionalParams = additionalParams;
        return this;
    }

    /**
     * Query API for report
     */
    protected fetch(): Promise<ReportTableData> {
        return new Promise<ReportTableData>((resolve, reject) => {
            /**
             * Map report api to local models
             */
            this.makeQuery()
                .fetchJson()
                .then((result) => {
                    this._meta = result.meta;
                    if (this.additionalReportFields !== null && result.meta?.reportFields) {
                        this.assignAdditionalReportFields();
                    }
                    const sheet = {
                        title: result.data?.attributes.sheet.title,
                        rows: result.data?.attributes.sheet.rows.map((row) => row as ReportTableRow),
                        columns: result.data?.attributes.sheet.headers.map((col) => col as ReportTableColumn),
                    } as ReportTableSheet;
                    resolve({
                        sheets: [sheet],
                    } as ReportTableData);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    protected makeQuery(): Query {
        return new Query(
            this._name,
            this._id,
            this._filters,
            this._page,
            this._sort,
            this._sortDirection,
            this._additionalParams
        );
    }

    private assignAdditionalReportFields(): void {
        const reportFields = Object.keys(this.additionalReportFields);
        for (const reportField of reportFields) {
            this.additionalReportFields[reportField] = this._meta.reportFields[reportField] ?? null;
        }
    }
}
